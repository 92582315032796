(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(factory);
    } else {
        factory();
    }
}(this, function() {
    App.module('LanguageSelector', function (LanguageSelector, App, Backbone, Marionette, $, _) {
        'use strict';

        var
            _LANGUAGE_CODES_TO_DOMAINS = {
                'gb'    : 'powerofvitality.com',
                'fr-ca' : 'fr-ca.powerofvitality.com',
                'fr'    : 'fr.powerofvitality.com',
                'es'    : 'es.powerofvitality.com',
                'de'    : 'de.powerofvitality.com',
                'pt'    : 'pt.powerofvitality.com'
            };

        function _getDomain(){
            //Use POV domain for PROD and external INTEG test ref, when MotionPoint is configured;
            //otherwise use window.location.host for cookie
            var domain = window.location.hostname.indexOf(_getPovDomain()) !== -1 ? _getPovDomain() : window.location.hostname;
            return domain;
        }

        function _getPermanentCookieDate(){
            var date = new Date();
            date.setTime(date.getTime() + (1000*60*60*24*365*20)); // 1000 miliseconds, 60 seconds, 60 minutes, 24hours, 365 days, 20years
            return date;
        }

        function _getPovDomain() {
            return '.powerofvitality.com';
        }

        function _isSubDomain(url) {

        // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
            url = url.replace(new RegExp(/^\s+/),""); // START
            url = url.replace(new RegExp(/\s+$/),""); // END

        // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
            url = url.replace(new RegExp(/\\/g),"/");

        // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
            url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i),"");

        // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
            url = url.replace(new RegExp(/^www\./i),"");

        // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
            url = url.replace(new RegExp(/\/(.*)/),"");

        // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
            if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
                url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i),"");

        // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
            } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
                url = url.replace(new RegExp(/\.[a-z]{2,4}$/i),"");
            }

        // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
            var subDomain = (url.match(new RegExp(/\./g))) ? true : false;

            return(subDomain);

        }

        function getLanguageCodeFromDomain() {
            var url = window.location.hostname;
            url = url.replace('www.', '');
            var urlArr = url.split('.'),
                firstWord = urlArr[0];

            if(firstWord.indexOf('test') > -1 || firstWord.indexOf('powerofvitality') > -1 || firstWord.indexOf('qa') > -1) {
                return 'en'
            } else {
                return firstWord;
            }
        }

        LanguageSelector.RecordEntity = Backbone.Model.extend({
            defaults: {
                name: null,
                code: null,
                active: false
            }
        });

        LanguageSelector.RecordEntities = Backbone.Collection.extend({
            model: LanguageSelector.RecordEntity,
            setActiveLanguage: function (languageCode) {
                var model = this.findWhere({code: languageCode});
                model && model.set({'active': true});
            }
        });

        LanguageSelector.RecordItemView = Marionette.ItemView.extend({
            template: "#languageSelectorRecord_Tmpl",
            ui: {
                trigger: '.jsTrigger'
            },
            triggers: {
                'click @ui.trigger': 'onLanguageSelected'
            },
            initialize: function () {
                this.listenTo(this.model, 'change:active', this.render);
            },
            onRender: function(){
                var $el = this.$el = this.$el.children();
                $el.unwrap();
                this.setElement($el);
            }
        });


        LanguageSelector.RecordsItemView = Marionette.CollectionView.extend({
            tagName: 'ul',
            className: 'languageList',
            childView: LanguageSelector.RecordItemView,
            onRender: function(){
                this.$el.prepend(this.$el.find('.active').parent());
            }
        });


        LanguageSelector.Controller = Marionette.Controller.extend({
            defaultLanguage: 'en',
            activeLanguage: null,
            LANGUAGES_AVAILABLE_FOR_TRANSLATION: [],
            initialize: function () {
                this.setupInitialPageURL();
                this.setupUIBasedOnUserSettings();
            },
            setupInitialPageURL: function(){
                this.checkNecessityInWWW();
                this.checkUserSelectedLanguageDomainHousing();
            },
            checkNecessityInWWW: function(){
                if (this.isOnLocalEnv()) {
                    return;
                }
                var currentLocation = window.top.location.href;
                if (!  _isSubDomain(currentLocation) && currentLocation.indexOf('www.') === -1) {
                    currentLocation = currentLocation.replace(/\:\/\//, '://www.');
                    window.top.location.href = currentLocation;
                }
            },
            checkUserSelectedLanguageDomainHousing: function(){
                var
                    userSelectedLanguage = $.cookie('MNCPORTAL_LANGUAGE'),
                    domainBasedLanguage = this.getCurrentDomainDrivenLanguage();

                if (userSelectedLanguage === undefined || this.isOnLocalEnv()) {
                    this.switchLanguage(this.defaultLanguage, true);
                }
                else if (userSelectedLanguage !== domainBasedLanguage) {
                    this.switchLanguage(userSelectedLanguage);
                }
                else {
                    this.activeLanguage = userSelectedLanguage;
                }
            },
            getCurrentDomainDrivenLanguage: function() {
                var
                    domain = location.hostname,
                    language = domain.split('.')[0];
                if (_LANGUAGE_CODES_TO_DOMAINS[language] !== undefined) {
                    return language;
                }
                return this.defaultLanguage;
            },
            switchLanguage: function (lang, isDefaultSetup) {
                var redirectIsNeeded = lang && (lang !== this.activeLanguage);
                this.activeLanguage = lang;

                if (this.isOnLocalEnv()) {
                    return;
                }
                var
                    domain = _getDomain(),
                    cookieOptions = {domain: domain, path: '/', expires: _getPermanentCookieDate()};

                $.cookie('USER_PRODUCT', 'us', cookieOptions);
                $.cookie('MNCPORTAL_LANGUAGE', lang, cookieOptions);

                if (redirectIsNeeded && (! isDefaultSetup || getLanguageCodeFromDomain() !== 'en')) {
                    // $.cookie('MNCPORTAL_WLJSESSIONID', $.cookie('MNCPORTAL_WLJSESSIONID'), {domain: domain, path: '/'});
                    $.removeCookie('MNCPORTAL_WLJSESSIONID', { path: '/' });
                    this.redirectToPortal(lang);
                }
            },
            redirectToPortal: function (language){
                if (this.isOnLocalEnv()) {
                    return;
                }
                var primeMoveToPortalUrl = _.property('moveToPortalUrl')(this.options);

                if (! primeMoveToPortalUrl) {
                    return;
                }
                window.top.location.href = this.correctRedirectLink(primeMoveToPortalUrl, language);
            },
            correctRedirectLink: function (primeMoveToPortalUrl, language) {
                var redirectLink = primeMoveToPortalUrl;
                var languageRegex = /(\:\/\/[a-z]{2,4}?\.)/;

                //In case of integrationtest (for local environment) it should be replaced by es.integrationtest
                //or just plain integrationtest
                redirectLink = redirectLink.replace(languageRegex, '://' + language + '.');

                if (language === 'en') {
                    //just for integration test
                    redirectLink = redirectLink.replace(languageRegex, '://');
                }
                // Add www for security reason
                if (
                    primeMoveToPortalUrl !== undefined
                    && primeMoveToPortalUrl.indexOf("www.") !== -1
                    && ! _isSubDomain(redirectLink)
                )
                {
                    redirectLink = redirectLink.replace(/\:\/\//, '://www.');
                }
                return redirectLink;
            },
            isOnLocalEnv: function(){
                return window.location.hostname === 'localhost';
            },
            prepareAvailableLanguagesConfig: function(){
                this.languagesMappedViaConfigTemplateSelector = _.property('languagesMappedViaConfigTemplateSelector')(this.options);

                var
                    $translatableRecordsHolder = $(this.languagesMappedViaConfigTemplateSelector),
                    $translatableRecordsHolderVector = $('<div />').html($translatableRecordsHolder.html());

                $translatableRecordsHolderVector.find('[data-record]').each(function (i, el) {
                    var $el = $(el);
                    this.LANGUAGES_AVAILABLE_FOR_TRANSLATION.push({
                        name: $.trim($el.html()),
                        code: $el.data('lang-codes-mapping')
                    });
                }.bind(this));
            },
            setupUIBasedOnUserSettings: function () {
                var
                    $mountPointDomPromise = _.property('$mountPointDomPromise')(this.options),
                    $mountPointDom = _.property('$mountPointDomPromise')(this.options);

                this.setupLanguageSelector();

                if ($mountPointDomPromise) {
                    $mountPointDomPromise.done(this.defineMountPointDomAndShowLanguageSelector.bind(this));
                    return;
                }
                this.defineMountPointDomAndShowLanguageSelector($mountPointDom);
            },
            setupLanguageSelector: function () {
                this.prepareAvailableLanguagesConfig();
                this.languagesCollection = new LanguageSelector.RecordEntities(this.LANGUAGES_AVAILABLE_FOR_TRANSLATION);
                this.languagesCollection.setActiveLanguage(this.activeLanguage);
            },
            showLanguageSelector: function () {
                this.languageSelectorRecordsItemView = new LanguageSelector.RecordsItemView({
                    collection: this.languagesCollection
                });
                this.listenTo(this.languageSelectorRecordsItemView, 'childview:onLanguageSelected', function(childview, eventOptions) {
                    if (this.isInPageOnloadProcessing) {
                        return;
                    }
                    this.languagesCollection.invoke('set', {'active': false});
                    var
                        model = eventOptions.model,
                        language = model.get('code');

                    model.set({'active': true});

                    this.languageSelectorRecordsItemView.render();

                    language && this.switchLanguage(language);
                });
                var mountPointRegion = new Marionette.Region({
                    el: this.$mountPointDom
                });
                mountPointRegion.show(this.languageSelectorRecordsItemView);

                this.bindOnBeforePageOnloadProcessing();
            },
            bindOnBeforePageOnloadProcessing: function(){
                $(window).on('beforeunload', function() {
                    this.isInPageOnloadProcessing = true;
                    setTimeout(function() {
                        setTimeout(function() {
                            this.isInPageOnloadProcessing = false;
                        }.bind(this), 1000);
                    }.bind(this),1);
                }.bind(this));
            },
            defineMountPointDomAndShowLanguageSelector: function($mountPointDom){
                (this.$mountPointDom = this.checkMountPointAcceptability($mountPointDom)) && this.showLanguageSelector();
            },
            checkMountPointAcceptability: function ($mountPointDom) {
                return ($mountPointDom && $mountPointDom.length && $mountPointDom);
            }
        });
    });
}));